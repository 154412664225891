<template>
  <slot />
</template>
<script setup>
</script>
<style>
html,
body,
#__nuxt,
#__layout {
  height: 100% !important;
  width: 100% !important;
}
</style>